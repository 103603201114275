import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from 'gatsby'
import ProcessNo from '../../images/process-no.svg'
import Angle from '../../images/icons/angle.svg'
import Key from '../../images/icons/key.svg'
import Chart from '../../images/icons/chart.svg'


import LayoutNo from "../../components/LayoutNo"
import HeaderNo from '../../components/HeaderNo'
import Seo from "../../components/Seo"
import ContactLauriNo from "../../components/ContactLauriNo"
import FactoryTourNo from "../../components/FactoryTourNo"
import SampleProjectsNo from "../../components/SampleProjectsNo"

const IndexPage = () => {
  return (
    <LayoutNo>

      <Seo title="Løsninger" description="Welement er en partner som hjelper entreprenører, ingeniører og utviklere med å videreutvikle byggenæringen" lang="no" />
      <HeaderNo />
      <div className="container-base">
        <div className="section-1-2-center">
          <h1 className="title-xl wel-mb-s">Løsninger</h1>
          <p className="body-l">Ethvert prosjekt går gjennom flere nivåer. Med prefabrikasjon gjelder det å involvere leverandører og spesialister så tidlig som mulig. Da vil ditt byggeprosjekt gå mye raskere og enklere noe som vil resultere i at hele prosessen vil bli mer kostnadseffektiv.</p></div>
      </div>

      <div className="bg-welement-green py-20 px-8 md:px-0">
        <div className="bg-welement-pink md:w-128 p-16 rounded-lg mx-auto">
          <img src={ProcessNo} alt="Icon" className="w-full"></img>
        </div>
      </div>

      <div className="bg-welement-white">
        <div className="container-base">
          <div className="section-1-2-center wel-mb-l">
            <h2 className="title wel-mb-s">Vår løsning</h2>
            <p className="body-l">Du trenger en pålitelig partner for å håndtere hele prosessen eller du trenger en leverandør på prefabrikerte elementer</p>
          </div>

          <div className="grid sm:grid-cols-3 gap-12 sm:gap-8">
            <div className="text-center">
              <img src={Angle} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-s">1. KONSULENT</h3>
              <p className="wel-mb-s">Har du ett boligkonsept eller konsept som trenger ett prefabrikkprodukt?</p>
              <Link to="#consultation" className="btn btn-black">Les mer</Link>
            </div>

            <div className="text-center">
              <img src={Key} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-s">2. PREFABRIKKPARTNER</h3>
              <p className="wel-mb-s">Mangler du en prefabrikkpartner til ditt prosjekt?</p>
              <Link to="#prefab-partner" className="btn btn-black">Les mer</Link>
            </div>

            <div className="text-center">
              <img src={Chart} alt="Icon" className="h-24 mx-auto wel-mb-s" />
              <h3 className="title-s">3. TOTALLEVERANDØR</h3>
              <p className="wel-mb-s">Trenger du en totalleverandør med fokus på effektive løsninger?</p>
              <Link to="#full-solution" className="btn btn-black">Les mer</Link>
            </div>
          </div>
        </div>

        <div id="consultation" className="bg-welement-pink px-8 md:px-0">
          <div className="container-base">
            <div className="section-1-2-center wel-mb-l">
              <h2 className="title wel-mb-s">1. Konsulent</h2>
              <p className="body-l">Har du ett hus konsept eller ett som passer til prefabrikkert produksjon som du ønsker bygge?</p>
            </div>

            <div className="grid sm:grid-cols-2 gap-8 wel-mb-l">
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">FÅ RÅD</h3>
                <p>Hvorfor ikke involvere oss i produktutviklingsprosessen og bruke vår utstrakte kunnskap om CAM/CAD design, arkitektonisk optimalisering og ett design tilpasset for prefabrikkproduksjon.</p>
              </div>
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">VÅR EKSPERTISE</h3>
                <p>I alle prosesser vi involveres tidlig i startfasen, kan selv de minste detaljene endre kostnadsbilde og prosjektet kan få et helt annet positivt økonomisk bilde.</p>
              </div>
            </div>

            <div className="grid sm:grid-cols-3 lg:w-3/4 mx-auto gap-12 sm:gap-8 wel-mb-l">
              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-1-1.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="DESIGNSTØTTE"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">DESIGNSTØTTE</h3>
                <p>Kunnskap om prosduksjon/design og montering<br />
Optimalisering av arkitiektur<br />
Mengde tilpassninger</p>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-1-2.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="TILPASSNINGER AV INNKJØP"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">TILPASSNINGER AV INNKJØP</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-1-3.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="UTVIKLING AV PREFABRIKERTE PRODUKTER"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">UTVIKLING AV PREFABRIKERTE PRODUKTER</h3>
              </div>
            </div>
            <div className="text-center bg-welement-white p-8 rounded sm:w-1/2 mx-auto">
              <h4 className="title-s mb-2">VÅR EKSPERTISE</h4>
              <ul>
                <li>Kunnskap om prosduksjon/design og montering</li>
                <li>Arkitiketurell optimalisering</li>
                <li>Tilpassninger av innkjøp</li>
                <li>Utvikling av prefabrikerte produkter</li>
              </ul>

            </div>
          </div>
        </div>

        <ContactLauriNo />

        <div id="prefab-partner" className="bg-welement-pink px-8 md:px-0">
          <div className="container mx-auto pt-20 pb-20">
            <div className="w-2/3 mx-auto text-center wel-mb-l">
              <h2 className="title wel-mb-s">2. Prefabrikert partner</h2>
              <p className="body-l">Er det en prefabrikert produsent som mangler i din verdikjede?</p>
            </div>

            <div className="grid grid-cols-2 gap-8 wel-mb-l">
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">KUNDEPROFIL</h3>
                <p>Du kjenner selv den prefabrikerte-prosessen, og har kontroll på planlegging, strukturell design og montering.</p>
              </div>
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">VÅRE TILBUD</h3>
                <p>Vi kan hjelpe deg med de siste skrittene av produsjonsdesign og elementproduksjon ved hjelp av ultra presist tyskt maskineri</p>
              </div>
            </div>

            <div className="grid grid-cols-4 mx-auto gap-8 wel-mb-l">
              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-1.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Design"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">Design</h3>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-2.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="PREFABRIKASJON"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">PREFABRIKASJON</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-3.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="VINDUSMONTERING"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">VINDUSMONTERING</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-4.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="PAKKING OG TRANSPORT"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">PAKKING OG TRANSPORT</h3>
              </div>

            </div>

            <div className="text-center bg-welement-white p-8 rounded sm:w-1/2 mx-auto">
              <h4 className="title-s mb-2">TYPISKE PROSJEKTER</h4>
              <ul className="mb-8">
                <li>Leilighetsbygg</li>
                <li>Rekkehus</li>
                <li>Enebolig</li>
                <li>Fasadesystemer</li>
              </ul>

              <h4 className="title-s mb-2">MULIGHETER PÅ FABRIKKEN</h4>
              <ul>
                <li>Terrasse og balkonger</li>
                <li>Utvendige takelementer</li>
                <li>Innvendige takelementer</li>
                <li>Gulvelementer</li>
                <li>Innvendige veggelementer</li>
                <li>Fasade og utvendige elementer</li>
              </ul>

            </div>
          </div>

        </div>

        <ContactLauriNo />

        <div id="full-solution" className="bg-welement-pink px-8 md:px-0">
          <div className="container mx-auto pt-20 pb-20">
            <div className="w-2/3 mx-auto text-center wel-mb-l">
              <h2 className="title wel-mb-s">3. Total leveranse</h2>
              <p className="body-l">Trenger du en totalleverandør for ditt prosjekt? Vi kan hjelpe deg.</p>
            </div>

            <div className="grid grid-cols-2 gap-8 wel-mb-l">
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">NIVÅ AV PREFABRIKASJON</h3>
                <p>Vi hjelper deg å definere de beste løsninger for prefabrikerte elementer.

</p>
              </div>
              <div className="bg-welement-white p-8 rounded">
                <h3 className="title-s mb-2">OMFANG OG LEVERING</h3>
                <p>Vi hjelper deg med å etablere det beste løsningen for levering, montering og gjennomføring av ditt prosjekt.</p>
              </div>
            </div>

            <div className="grid grid-cols-3 w-3/4 mx-auto gap-8 wel-mb-l">
              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-1.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="Welement"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">DESIGN</h3>
                <p className="body-s">Strukturell design</p>
              </div>
              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-2.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="PREFABRIKASJON"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">PREFABRIKASJON</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-3.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="VINDUSMONTERING"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">VINDUSMONTERING</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-4.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="PAKKING OG TRANSPORT"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">PAKKING OG TRANSPORT</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-5.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="MONTERING"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">MONTERING</h3>
              </div>

              <div className="text-center">
                <StaticImage
                  src="../../images/solutions/grid-3-6.png"
                  quality={100}
                  formats={["auto", "webp", "avif"]}
                  alt="UTVENDIG FERDIGSTILLELSE"
                  loading="eager"
                  placeholder="blurred"
                  className="w-32 h-32 mx-auto rounded-full wel-mb-s"
                />
                <h3 className="title-s mb-2">UTVENDIG FERDIGSTILLELSE</h3>
              </div>

            </div>

            <div className="text-center bg-welement-white p-8 rounded sm:w-1/2 mx-auto">
              <h4 className="title-s mb-2">PROSJEKTTYPER</h4>
              <ul className="mb-8">
                <li>Leilighetsbygg</li>
                <li>Rekkehus</li>
                <li>Enebolig</li>
                <li>Fasadesystemer</li>
              </ul>

              <h4 className="title-s mb-2">MULIGHETER MED FABRIKKEN</h4>
              <ul>
                <li>Terrasse og balkong</li>
                <li>Utvendige takelementer</li>
                <li>Innvendige takelementer</li>
                <li>Gulvelementer</li>
                <li>Invvendige elementer</li>
                <li>Fasade og utvendige veggelementer</li>
              </ul>

            </div>
          </div>

        </div>

        <ContactLauriNo />

        <FactoryTourNo />

        <div className="bg-welement-white">
          <SampleProjectsNo />
        </div>


      </div >

    </LayoutNo >
  )

};

export default IndexPage;
